$(document).ready ()->
#  $.fn.offsetBreadcrumbs()
#  $(window).resize ()->
#    $.fn.offsetBreadcrumbs()
  if !$(document).find(".site-header").hasClass("site-header--active") && $(document).scrollTop() > 0
    $(document).find(".site-header").addClass("site-header--active")

  $(document).on "click", ".anq--section>.gallery-buttons__item", ()->
    $formblock = $(document).find(".qna--items")
    itemCode = $(@).attr("data-item")
    itemCodeActual = $(document).find(".anq--section>.gallery-buttons__item.gallery-buttons__item--active").attr("data-item")
    $(document).find(".anq--section>.gallery-buttons__item.gallery-buttons__item--active").removeClass("gallery-buttons__item--active")
    $(@).addClass("gallery-buttons__item--active")

    console.log itemCode, itemCodeActual

    $formblock.find(".accordion[data-item='"+itemCodeActual+"']").animate({opacity: 0}, 200, ()->
      $formblock.find(".accordion[data-item='"+itemCodeActual+"']").removeClass("qna--show")
      $formblock.find(".accordion[data-item='"+itemCodeActual+"']").attr("style", "")

      $formblock.find(".accordion[data-item='"+itemCode+"']").css("display", "block")
      $formblock.find(".accordion[data-item='"+itemCode+"']").animate({opacity: 1}, 300, ()->
        $formblock.find(".accordion[data-item='"+itemCode+"']").addClass("qna--show")
        $formblock.find(".accordion[data-item='"+itemCode+"']").attr("style", "")
      )
    )

  MAX_SIZE_OF_FILE = 30
  $(document).find(".this-field-is-phone").mask("+7 (999) 999-99-99")
  $(document).on "focus click", ".this-field-is-phone", ()->
    console.log $(@).val()
    if $(@).val() == "+7 (___) ___-__-__" || !$(@).val()
      $(@)[0].setSelectionRange(4,4)
  Fancybox.bind("[data-fancybox]", {

  })
  $(document).on "click", ".profile-more", ()->
    $.ajax {
      type: "POST",
      url: $(@).attr("data-url"),
      # data: {AJAXPORTFOLIO: "Y"}
      success: (data) =>
        $(document).find("#profile-ajax-block .profile-more").remove()
        $(document).find("#profile-ajax-block .portfolio-nav-main-page").remove()
        $(document).find("#profile-ajax-block").append(data)
        if document.querySelector('.portfolio-nav-main-page .open__modal__uslug')
          e = 0
          while e < document.querySelectorAll('.portfolio-nav-main-page .open__modal__uslug').length
            document.querySelectorAll('.portfolio-nav-main-page .open__modal__uslug')[e].addEventListener 'click', ->
              document.querySelector('.modal-uslug').style.display = 'block'
              document.querySelector('.overlay').style.display = 'block'
              document.querySelector('body').style.overflow = 'hidden'
              return
            e++
        $.ajax {
          type: "POST",
          url: $(@).attr("data-url-modal"),
          # data: {AJAXPORTFOLIO: "Y"}
          success: (data) ->
            $(document).find(".modal-gallery-alt .swiper-wrapper").append($(data).find(".swiper-wrapper").html())
            $(document).find(".modal-gallery>.modal__container").html($(document).find(".modal-gallery-alt>.modal__container").html())
            $.fn.initProfileModal()
        }
    }

  if $(document).find(".modal-gallery__init").is("div")
    $.fn.initProfileModal()

  $(document).on "change", "#form-send-service-order input[type='file']", ()->
    form = $(document).find("#form-send-service-order")
    sizeOfFile = this.files[0].size / 1024 / 1024
    if sizeOfFile > MAX_SIZE_OF_FILE
      alert("Максимальный размер файла "+MAX_SIZE_OF_FILE+"мб")
      $.fn.formFieldAddState(form, $(@), "error")
    else
      form.find(".input-block[data-name='" + $(@).attr("name") + "'] .input-block__label>span").html(this.files[0].name)
      $.fn.formFieldAddState(form, $(@), "success")

  $(document).on "change", "#form-send-service-order input[type='checkbox']", ()->
    form = $(document).find("#form-send-service-order")
    if $(@).prop("checked")
      $.fn.formFieldAddState(form, $(@), "success")

  $(document).on "click", "#form-send-service-order .select-custom__list-item", ()->
    value = $(@).attr("data-text")
    form = $(document).find("#form-send-service-order")
    select = form.find("select")
    select.val(value)
    if select.val()
      $.fn.formFieldAddState(form, form.find("select"), "success")

  $(document).on "change", "#form-send-service-order select", ()->
    form = $(document).find("#form-send-service-order")
    if $(@).val()
      $.fn.formFieldAddState(form, $(@), "success")

  $(document).on "change", "#form-send-service-order input[type='text']", ()->
    form = $(document).find("#form-send-service-order")
    console.log $(@).val()
    if $(@).attr("name") == "email"
      if $(@).val() && $.fn.validateEmail($(@).val())
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")
    else
      if $(@).val()
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")

  $(document).on "click", "#form-send-service-order button", ()->
    form = $(document).find("#form-send-service-order")
    phoneOrEmailMode = form.hasClass("fsa__p-or-e")
    hasError = false
    hasErrorFieldCode = []

    formData = new FormData();

    form.find("input[type='text'], input[type='checkbox'], textarea, select").each ()->
      formData.append($(@).attr("name"), $(@).val())
      if $(@).attr("name") == "email"
        if !$(@).val() || !$.fn.validateEmail($(@).val())
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("type") == "checkbox"
        formData.append($(@).attr("name"), $(@).prop("checked"))
        if !$(@).prop("checked")
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("type") == "file"
        if !this.files[0]
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          formData.append($(@).attr("name"), this.files[0], this.files[0].name)
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).is("textarea")
#         dataForSend[$(@).attr("name")] = $(@).val()
      else if $(@).is("select")
#        dataForSend[$(@).attr("name")] = $(@).val()
      else
        if !$(@).val()
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      console.log $(@).attr("name"), hasError

    if phoneOrEmailMode && hasErrorFieldCode.length == 1
      if hasErrorFieldCode[0] == 'email' || hasErrorFieldCode[0] == 'phone'
        hasError = false
        $.fn.formFieldAddState(form, $(document).find("#form-send-service-order input[name='email'], #form-send-service-order input[name='phone']"), "success")
        $.fn.formFieldAddState(form, $(document).find("#form-send-service-order input[name='"+hasErrorFieldCode[0]+"']"), "clear")

    if !hasError
      urlSend = "/ajax/addResultFormServiceOrder.php"
      if $(@).hasClass("sendMini")
        urlSend = "/ajax/addResultFormServiceOrderMini.php"

      console.log(urlSend, $(@).hasClass("sendMini"))

      $.fn.changeFormState(form, "load")
      $.ajax {
        type: "POST",
        url: urlSend,
        data: formData,
        processData: false,
        contentType: false,
        enctype: 'multipart/form-data',
        success: (data)->
          if data.success
            $.fn.changeFormState(form, "success")
          else
            $.fn.changeFormState(form, "")
      }
    else
      console.log "Ошибка"

  $(document).on "change", "#form-send-application input[type='checkbox']", ()->
    form = $(document).find("#form-send-application")
    if $(@).prop("checked")
      $.fn.formFieldAddState(form, $(@), "success")

  $(document).on "change", "#form-send-application input[type='text']", ()->
    form = $(document).find("#form-send-application")
    console.log $(@).val()
    if $(@).attr("name") == "email"
      if $(@).val() && $.fn.validateEmail($(@).val())
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")
    else
      if $(@).val()
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")


  $(document).on "click", "#form-send-application button", ()->
    form = $(document).find("#form-send-application")
    phoneOrEmailMode = form.hasClass("fsa__p-or-e")
    hasError = false
    hasErrorFieldCode = []


    dataForSend = {}

    form.find("input[type='text'], input[type='checkbox'], textarea").each ()->
      dataForSend[$(@).attr("name")] = $(@).val()
      if $(@).attr("name") == "email"
        if !$(@).val() || !$.fn.validateEmail($(@).val())
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("type") == "checkbox"
        dataForSend[$(@).attr("name")] = $(@).prop("checked")
        if !$(@).prop("checked")
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).is("textarea")
        dataForSend[$(@).attr("name")] = $(@).val()
      else
        if !$(@).val()
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")

    console.log(hasErrorFieldCode)
    if phoneOrEmailMode && hasErrorFieldCode.length == 1
      if hasErrorFieldCode[0] == 'email' || hasErrorFieldCode[0] == 'phone'
        hasError = false
        $.fn.formFieldAddState(form, $(document).find("#form-send-application input[name='email'], #form-send-application input[name='phone']"), "success")
        $.fn.formFieldAddState(form, $(document).find("#form-send-application input[name='"+hasErrorFieldCode[0]+"']"), "clear")

    if !hasError
      $.fn.changeFormState(form, "load")
      $.ajax {
        type: "POST",
        url: "/ajax/addResultFormApplication.php",
        data: dataForSend,
        success: (data)->
          if data.success
            $.fn.changeFormState(form, "success")
          else
            $.fn.changeFormState(form, "")
      }
  $(document).on "change", "#form-send-review input[type='checkbox']", ()->
    form = $(document).find("#form-send-review")
    if $(@).prop("checked")
      $.fn.formFieldAddState(form, $(@), "success")

  $(document).on "change", "#form-send-review input[type='text']", ()->
    form = $(document).find("#form-send-review")
    console.log $(@).val()
    if $(@).attr("name") == "email"
      if $(@).val() && $.fn.validateEmail($(@).val())
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")
    else
      if $(@).val()
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")


  $(document).on "click", "#form-send-review button", ()->
    form = $(document).find("#form-send-review")
    phoneOrEmailMode = form.hasClass("fsa__p-or-e")
    hasError = false
    hasErrorFieldCode = []


    dataForSend = {}

    form.find("input[type='text'], input[type='checkbox'], textarea").each ()->
      dataForSend[$(@).attr("name")] = $(@).val()
      if $(@).attr("name") == "email"
        if !$(@).val() || !$.fn.validateEmail($(@).val())
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("type") == "checkbox"
        dataForSend[$(@).attr("name")] = $(@).prop("checked")
        if !$(@).prop("checked")
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).is("textarea")
        if !$(@).val()
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else
        if !$(@).val()
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")

    console.log(hasError)
    console.log(hasErrorFieldCode)

    if !hasError
      $.fn.changeFormState(form, "load")
      $.ajax {
        type: "POST",
        url: "/ajax/addResultFormReview.php",
        data: dataForSend,
        success: (data)->
          if data.success
            $.fn.changeFormState(form, "success")
          else
            $.fn.changeFormState(form, "")
      }
  $(document).on "change", "#form-send-calculator--include input[type='checkbox']", ()->
    form = $(document).find("#form-send-calculator--include")
    if $(@).prop("checked")
      $.fn.formFieldAddState(form, $(@), "success")

  $(document).on "change", "#form-send-calculator--include input[type='text']", ()->
    form = $(document).find("#form-send-calculator--include")
    console.log $(@).val()
    if $(@).attr("name") == "email"
      if $(@).val() && $.fn.validateEmail($(@).val())
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")
    else
      if $(@).val()
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")

  $(document).on "click", "#form-send-calculator--include button", ()->
    form = $(document).find("#form-send-calculator--include")
    hasError = false

    dataForSend = {}

    form.find("input[type='hidden'], input[type='text'], input[type='checkbox'], textarea").each ()->
      dataForSend[$(@).attr("name")] = $(@).val()
      if $(@).attr("type") == "hidden"
        dataForSend[$(@).attr("name")] = $(@).val()
#        $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("name") == "model_url"
        dataForSend[$(@).attr("name")] = $(@).val()
#        $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("name") == "email"
        dataForSend[$(@).attr("name")] = $(@).val()
        if !$(@).val() || !$.fn.validateEmail($(@).val())
          hasError = true
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("type") == "checkbox"
        dataForSend[$(@).attr("name")] = $(@).prop("checked")
        if !$(@).prop("checked")
          hasError = true
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).is("textarea")
        dataForSend[$(@).attr("name")] = $(@).val()
      else
        dataForSend[$(@).attr("name")] = $(@).val()
        if !$(@).val()
          hasError = true
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")

    if !hasError
      $.fn.changeFormState(form, "load")
      $.ajax {
        type: "POST",
        url: "/ajax/addResultFormCalculator.php",
        data: dataForSend,
        success: (data)->
          if data.success
            $.fn.changeFormState(form, "success")
          else
            $.fn.changeFormState(form, "")
      }
  $(document).on "change", "#form-send-calculator input[type='checkbox']", ()->
    form = $(document).find("#form-send-calculator")
    if $(@).prop("checked")
      $.fn.formFieldAddState(form, $(@), "success")

  $(document).on "change", "#form-send-calculator input[type='text']", ()->
    form = $(document).find("#form-send-calculator")
    console.log $(@).val()
    if $(@).attr("name") == "email"
      if $(@).val() && $.fn.validateEmail($(@).val())
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")
    else
      if $(@).val()
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")

  $(document).on "click", "#form-send-calculator button", ()->
    form = $(document).find("#form-send-calculator")
    hasError = false

    dataForSend = {}

    form.find("input[type='hidden'], input[type='text'], input[type='checkbox'], textarea").each ()->
      dataForSend[$(@).attr("name")] = $(@).val()
      if $(@).attr("type") == "hidden"
        dataForSend[$(@).attr("name")] = $(@).val()
#        $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("name") == "model_url"
        dataForSend[$(@).attr("name")] = $(@).val()
#        $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("name") == "email"
        dataForSend[$(@).attr("name")] = $(@).val()
        if !$(@).val() || !$.fn.validateEmail($(@).val())
          hasError = true
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("type") == "checkbox"
        dataForSend[$(@).attr("name")] = $(@).prop("checked")
        if !$(@).prop("checked")
          hasError = true
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).is("textarea")
        dataForSend[$(@).attr("name")] = $(@).val()
      else
        dataForSend[$(@).attr("name")] = $(@).val()
        if !$(@).val()
          hasError = true
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")

    if !hasError
      $.fn.changeFormState(form, "load")
      $.ajax {
        type: "POST",
        url: "/ajax/addResultFormCalculator.php",
        data: dataForSend,
        success: (data)->
          if data.success
            $.fn.changeFormState(form, "success")
          else
            $.fn.changeFormState(form, "")
      }

  $(document).on "change", "#form-send-request input[type='checkbox']", ()->
    form = $(document).find("#form-send-request")
    if $(@).prop("checked")
      $.fn.formFieldAddState(form, $(@), "success")

  $(document).on "change", "#form-send-request input[type='text']", ()->
    form = $(document).find("#form-send-request")
    if $(@).attr("name") == "email"
      if $(@).val() && $.fn.validateEmail($(@).val())
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")
    else
      if $(@).val()
        $.fn.formFieldAddState(form, $(@), "success")
      else
        $.fn.formFieldAddState(form, $(@), "error")

  $(document).on "click", "#form-send-request button", ()->
    form = $(document).find("#form-send-request")
    phoneOrEmailMode = form.hasClass("fsr__p-or-e")
    hasError = false
    hasErrorFieldCode = []

    dataForSend = {}

    $(document).find("#form-send-request input[type='text'], #form-send-request input[type='checkbox']").each ()->
      dataForSend[$(@).attr("name")] = $(@).val()
      if $(@).attr("name") == "email"
        if !$(@).val() || !$.fn.validateEmail($(@).val())
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else if $(@).attr("type") == "checkbox"
        dataForSend[$(@).attr("name")] = $(@).prop("checked")
        if !$(@).prop("checked")
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")
      else
        if !$(@).val()
          hasError = true
          hasErrorFieldCode.push($(@).attr("name"))
          $.fn.formFieldAddState(form, $(@), "error")
        else
          $.fn.formFieldAddState(form, $(@), "success")

    if phoneOrEmailMode && hasErrorFieldCode.length == 1
      if hasErrorFieldCode[0] == 'email' || hasErrorFieldCode[0] == 'phone'
        hasError = false
        $.fn.formFieldAddState(form, $(document).find("#form-send-request input[name='email'], #form-send-request input[name='phone']"), "success")
        $.fn.formFieldAddState(form, $(document).find("#form-send-request input[name='"+hasErrorFieldCode[0]+"']"), "clear")

    if !hasError
      $.fn.changeFormState(form, "load")
      $.ajax {
        type: "POST",
        url: "/ajax/addResultFormSendRequest.php",
        data: dataForSend,
        success: (data)->
          if data.success
            $.fn.changeFormState(form, "success")
          else
            $.fn.changeFormState(form, "")
      }

$.fn.changeFormState = (form, formState)->
  form.removeClass("load")
  form.removeClass("success")
  if formState == "success"
    form.addClass("success")
  if formState == "load"
    form.addClass("load")

$.fn.formFieldAddState = (form, field, stateCode)->
  fieldBlock = form.find(".input-block[data-name='" + field.attr("name") + "']")
  if !fieldBlock.is("div")
    fieldBlock = form.find(".checkbox-custom[data-name='" + field.attr("name") + "']")
  if !fieldBlock.is("div")
    fieldBlock = form.find(".select-custom[data-name='" + field.attr("name") + "']")
  fieldBlock.removeClass("input-block__success")
  fieldBlock.removeClass("input-block__error")
  if stateCode == "success"
    fieldBlock.addClass("input-block__success")
  else if stateCode == "error"
    fieldBlock.addClass("input-block__error")
  else if stateCode == "clear"
    fieldBlock.removeClass("input-block__error")
    fieldBlock.removeClass("input-block__success")

$.fn.validateEmail = (email) ->
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

$.fn.initProfileModal = ()->
  console.log "sfdsfds"
  profileModal = new Swiper(".modal-gallery .modal-gallery__init", { slidesPerView: 1, spaceBetween: 25, centeredSlides: !0, loop: !0, simulateTouch: !1 });
  $(document).find(".modal-gallery .swiper-v").each ()->
    modelElem = $(document).find(".modal-gallery .modal-gallery__init[data-slide-id='"+$(@).attr("data-slide-id")+"']")
    new Swiper(@, {
        slidesPerView: 1,
        navigation: { nextEl: @.parentElement.querySelector(".single-slider__button-next"), prevEl: @.parentElement.querySelector(".single-slider__button-prev") },
        pagination: {
            el: @.parentElement.querySelector(".single-slider__pagination"),
            type: "fraction",
            formatFractionCurrent: (e) ->
                return ("0" + e).slice(-2)
            formatFractionTotal: (e) ->
                return ("0" + e).slice(-2)
            renderFraction: (e, t) ->
                return '<span class="' + e + '"></span> / <span class="' + t + '"></span>'
        },
    });
    console.log "g2w3g3"

  $(document).on "click", ".gallery__item", ()->
    $(document).find(".modal-gallery").css('display', 'block')
    ind = $(document).find(".modal-gallery .swiper-slide[data-slide-id='"+$(@).attr("data-gallery")+"']").attr("data-swiper-slide-index")
    console.log ind
    $(document).find(".modal-gallery .swiper-slide[data-slide-id='23408']").each () ->
      if $(@).attr("data-swiper-slide-index")
        ind = $(@).attr("data-swiper-slide-index")
    console.log ind
    if parseInt(ind)+1 == $(document).find("#profile-ajax-block .gallery-desktop .gallery__item").length
      ind = 0
    else
      ind = parseInt(ind)+1
    profileModal.slideTo(ind, 0)

  $(document).on "click", ".modal-gallery .modal-gallery__item-footer-close", ()->
    $(document).find(".modal-gallery .modal__close").click();

$.fn.offsetBreadcrumbs = ()->
  $(document).find(".breadcrumbs").each ()->
    $(this).css("top", $(this).innerWidth()+50)
    $(this).css("opacity", 1)